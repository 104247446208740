.container {
  max-width: 120rem;
  margin: 0 auto;
}

.grid {
  display: grid;
  gap: 9rem;
}

.grid-two-column {
  grid-template-columns: repeat(2, 1fr);
}

.grid-three-column {
  grid-template-columns: repeat(3, 1fr);
}

.grid-four-column {
  grid-template-columns: 1fr 1.2fr 0.5fr 0.8fr;
}

.grid-five-column {
  grid-template-columns: repeat(5, 1fr);
}

.common-heading {
  font-size: 3.8rem;
  font-weight: 600;
  margin-bottom: 6rem;
  text-transform: capitalize;
}

.intro-data {
  margin-bottom: 0;
  text-transform: uppercase;
  color: #5138ee;
}

/* .caption {
  position: absolute;
  top: 15%;
  right: 10%;
  text-transform: uppercase;
  background-color: #f6f8fa;
  color: #8490ff;
  padding: 0.8rem 2rem;
  font-size: 1.2rem;
  border-radius: 2rem;
} */

input,
textarea {
  max-width: 50rem;
  color: #212529;
  padding: 1.6rem 2.4rem;
  border: 1px solid rgba(98, 84, 243, 0.5);

  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

@media (max-width: 998px) {
  .container {
    max-width: 130rem;
    padding: 0 3.2rem;
  }
}

@media (max-width: 768px) {
  html {
    font-size: 50%;
  }

  .grid {
    gap: 3.2rem;
  }
  .grid-two-column,
  .grid-three-column,
  .grid-four-column {
    grid-template-columns: 1fr;
  }
}
