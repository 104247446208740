.cardss {
  display: flex;
  width: 100%;
  gap: 1rem;
  justify-content: center;
  align-items: center;
}
.card {
  display: flex;
  gap: 1rem;
  justify-content: center;
  align-items: center;
}

.rental-card {
  display: flex;
  justify-content: space-around;
  align-items: stretch;
  /* flex-wrap: wrap; */

  width: 100%;
  max-width: 990px;
  margin-top: 1rem;
  border-radius: 10px;
  padding: 1rem;
  /* background-color: #242424; */
  background-color: rgb(51 65 85);
  border: 2px solid #00df987e;
  box-shadow: #00df9865 0px 8px 24px;
  color: #fbfbfb;
}
.card-img {
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  padding: 0.5rem;
  /* flex: 1 1 35%; */
}
.card-img img {
  /* height: 156px; */
  margin-right: 1rem;
  /* margin-right: 2rem; */
  aspect-ratio: 16/9;
  object-fit: cover;
  height: auto;
  max-width: clamp(200px, 100%, 480px);
  transition: all 100ms ease-in-out;
}
.card-img:hover img {
  transform: scale(1.01);
}
.card-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: 1rem;
  flex: 1 1 auto;
  width: 100%;
}
.card-title {
  font-size: 1.5rem;
  letter-spacing: 2px;
  font-weight: 500;
  text-align: left;
  color: white;
}
.star-container {
  display: flex;
}
.Ratings {
  background-color: #16a34a;
  color: #fbfbfb;
  font-size: 14px;
  line-height: 16px;
  padding: 0.25rem 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 28px;
  aspect-ratio: 1;
  border-radius: 50%;
}
.starRatings {
  display: flex;
  justify-content: center;
  align-items: center;
}
.tags {
  display: flex;
  justify-content: center;
  align-items: center;
}
.stars {
  height: 1rem;
}
.checked {
  color: #00df9a;
  font-size: 1rem;
}
p.rating-number {
  font-size: 0.7rem;
  font-weight: 300;
}
.place {
  text-align: left;
  color: #fff;
  font-weight: 400;
  letter-spacing: 2px;
  font-size: 16px;
}
.tags > p {
  text-align: left;
  font-size: 0.9rem;
  font-weight: 300;
  padding: 1px;
  border-radius: 0.2rem;
  background-color: greenyellow;
  /* width: fit-content; */
  /* margin-top: -0.5rem; */
  color: black;
}

.buttons {
  display: flex;
  margin-top: 0.5rem;
  justify-content: space-between;
  align-items: center;
  gap: 0.5rem;
}

/* CSS */
.button {
  appearance: none;
  margin: 0 2.5rem;
  background-color: transparent;
  border: 2px solid #00df9a;
  border-radius: 10px;
  box-sizing: border-box;
  color: #00df9a;
  cursor: pointer;
  display: flex;
  font-family: inherit;
  font-size: 10px;
  font-weight: 600;
  line-height: normal;
  margin: 0;
  min-height: 30px;
  min-width: 0;
  outline: none;
  padding: 3px 6px;
  text-align: center;
  text-decoration: none;
  transition: all 300ms cubic-bezier(0.23, 1, 0.32, 1);
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  width: 70%;
  will-change: transform;
}

.button-28:disabled {
  pointer-events: none;
}

.button-28:hover {
  color: #fff;
  background-color: #1a1a1a;
  box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
  transform: translateY(-2px);
}

.button-28:active {
  box-shadow: none;
  transform: translateY(0);
}
.tags > svg {
  fill: #00df9a;
}
