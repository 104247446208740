/*  */
.main {
  display: flex;
  /* width: 80%; */
  margin-top: 7rem;
  justify-content: space-around;
}
@media screen and (max-width: 992px) {
  .main {
    flex-direction: column-reverse;
    justify-content: space-between;
  }
}

.content {
  display: flex;
  flex-direction: column;
  width: 60%;
  justify-content: space-between;
  height: 40vh;
  margin: 0 auto;
}
.home__title {
  font-size: 5rem;
  font-weight: 700;
  text-align: center;
}
.home__subtitle {
  font-weight: 400;
  font-size: 2.5em;
  margin-top: -5rem;
  text-align: center;
}
.home__description {
  margin-top: -4rem;
  text-align: center;
}
.img-section {
  width: 300px;
  height: 100px;
  animation: profile__animate 8s ease-in-out infinite 1s;
  box-shadow: inset 0 0 0 9px rgb(255 255 255 / 30%);
  margin: 0 auto;
  /* margin-right: 10rem; */
  margin-right: 10rem;
}
.home_img {
  animation: profile__animate 8s ease-in-out infinite 1s;
  box-shadow: inset 0 0 0 9px rgb(255 255 255 / 30%);
  width: 500px;
}
@keyframes profile__animate {
  0% {
    border-radius: 60% 40% 30% 70%/60% 30% 70% 40%;
  }
  50% {
    border-radius: 30% 60% 70% 40%/50% 60% 30% 60%;
  }
  100% {
    border-radius: 60% 40% 30% 70%/60% 30% 70% 40%;
  }
}
@media screen and (max-width: 792px) {
  .content {
    margin-top: 10rem;
    height: 10rem;
  }
  .img-section {
    width: 150px;
    height: 150px;
    animation: profile__animate 8s ease-in-out infinite 1s;
    box-shadow: inset 0 0 0 9px rgb(255 255 255 / 30%);
    margin: 0 auto;
  }
  .home__title {
    font-size: 5rem;
    font-weight: 700;
    text-align: center;
  }
  .home__subtitle {
    font-weight: 400;

    font-size: -1rem;
  }
  .home__description {
    margin-top: 0rem;
  }
}
@media screen and (max-width: 592px) {
  .home__title {
    font-size: 3rem;
    font-weight: 700;
  }
  .home__subtitle {
    font-weight: 400;
    font-size: 1.5em;
    margin-top: -9rem;
  }
  .home__description {
    font-size: 0.6rem;
    margin-top: -10.5rem;
  }
}
