.search-box {
  --webkit-appearance: none;
  outline: none;
  width: 100%;
  max-width: 720px;
  margin: 1rem auto;
  padding: 1rem 1.25rem;
  border-radius: 25px;
  font-size: 1rem;
  line-height: 1.2rem;
  font-weight: 500;
}
