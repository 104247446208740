.plans-container {
  /* margin-top: -4rem; */
  padding: 0 2rem;
  display: flex;
  flex-direction: column;
  gap: 4rem;
  position: relative;
}
.events {
  /* background-color: black; */
}
.eventHead {
  text-align: center;
  color: #00df9a;
  font-size: 2.5rem;
}
.programs-header {
  display: flex;
  gap: 5rem;
  font-weight: bold;
  font-size: 3rem;
  justify-content: center;
  color: white;
  text-transform: uppercase;
  font-style: italic;
}

.plans {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15rem;
}
.plan {
  display: flex;
  flex-direction: column;
  background-color: #656565;
  color: white;
  gap: 2rem;
  padding: 1.5rem;
  width: 18rem;
}
.plan:nth-child(2) {
  background: linear-gradient(210.41deg, #00df9a 1.14%, #0000 100.75%);
  transform: scale(1.1);
}
.plan > svg {
  fill: #00df9a;
  width: 2rem;
  height: 2rem;
}
.plan > :nth-child(2) {
  font-size: 1rem;
  font-weight: bold;
}
.plan > :nth-child(3) {
  font-size: 3rem;
  font-weight: bold;
}

.features {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.feature {
  display: flex;
  align-items: center;
  gap: 1rem;
}
.feature > img {
  width: 1rem;
}
.plans > :nth-child(2) > svg {
  fill: white;
}
.plans > :nth-child(2) > button {
  fill: white;
  color: #00df91;
}
@media screen and (max-width: 768px) {
  .plans {
    flex-direction: column;
    gap: 3rem;
  }
}

.stroke-text {
  color: transparent;
  font-family: Arial, Helvetica, sans-serif;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #00df91;
}
.btn {
  background-color: white;
  padding: 0.5rem;
  font-weight: bold;
  border: 4px solid transparent;
  transition: 300ms all;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  color: #00df91;
}
.btn:hover {
  cursor: pointer;
}
.blur {
  background: rgba(253, 120, 43, 0.69);
  position: absolute;

  border-radius: 50%;

  filter: blur(225px);
  z-index: 0;
}
a {
  color: white;
  text-decoration: none;
}
.line {
  width: 100vw;
  background-color: #00df9a;
  height: 0.5rem;
}
@media screen and (max-width: 786px) {
  .programs-header {
    flex-direction: column;
    gap: 1rem;
    font-size: x-large;
    align-items: center;
    justify-content: center;
    margin-top: 0.7rem;
  }
}
